import React from "react"
import styled from "styled-components"
import logo_horizontal_white from "../images/logo_horizontal_white.png"

export class Footer extends React.Component {
  render() {
    return(
      <Main>
        <img src={logo_horizontal_white} alt="Performance First"/>
        <div></div>
        <a href="https://www.opt.ne.jp/contact_marketing/" target="_blank" rel="noopener noreferrer">広告出稿希望の方はこちら</a>
        <div>&copy; OPT Inc,</div>
      </Main>
    )
  }
}

const Main = styled.footer`
  width: 100%;
  color: #fff;
  background: #000518;
  text-align: center;
  padding: 24px;

  > img {
    width: 60%;
    max-width: 300px;
    margin-bottom: 16px;
  }

  > a {
    text-decoration: none;
    color: white;
  }
`
