import React from "react"
import styled from "styled-components"

export class Buttons extends React.Component {
  render() {
    return(
      <Main>
        <Business />
        <Customer />
      </Main>
    )
  }
}

export class Business extends React.Component {
  render() {
    return(
      <Company href="https://www.opt.ne.jp/contact_marketing/" target="_blank" rel="noopener noreferrer" />
    )
  }
}

export class Customer extends React.Component {
  render() {
    let tempLink = "https://console.performancefirst.jp/report/index.php?a=login/seller/SellerRegist&nocache=";
    return(
      <Affiliate href={tempLink} target="_blank" rel="noopener noreferrer" className="btn_form_link" />
    )
  }
}

const Main = styled.div`
  margin: 64px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`


const Company = styled.a`
  position: relative;
  display: block;
  color: #fff;
  width: 280px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  padding: 8px 16px;
  margin: 8px 32px;
  cursor: pointer;
  z-index: 1;

  &::after {
    content: "企業ご担当者様はこちら";
    position: absolute;
    display: block;
    color: #fff;
    left: 0;
    top: 0;
    width: 280px;
    height: 44px;
    background: transparent linear-gradient(126deg, #5C63E6 0%, #1f2599 100%) 0% 0% no-repeat padding-box;
    z-index: 0;
  }

  &::before {
    position: absolute;
    left: 8px;
    top: 8px;
    content: "";
    display: block;
    width: 280px;
    height: 44px;
    padding: 4px 12px;
    border: 4px solid #5C63E6;
    transition: left .5s, top .3s;
  }

  &:hover {
    &::before{
      transition: left .3s, top .3s;
      left: 0;
      top: 0;
    }
  }
`

const Affiliate = styled.a`
  position: relative;
  display: block;
  color: #fff;
  width: 280px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  padding: 8px 16px;
  margin: 8px 32px;
  cursor: pointer;
  z-index: 1;

  &::after {
    content: "アフィリエイトオーナー様はこちら";
    position: absolute;
    display: block;
    color: #fff;
    left: 0;
    top: 0;
    width: 280px;
    height: 44px;
    background: transparent linear-gradient(126deg, #5C63E6 0%, #1f2599 100%) 0% 0% no-repeat padding-box;
    z-index: 0;
  }

  &::before {
    position: absolute;
    left: 8px;
    top: 8px;
    content: "";
    display: block;
    width: 280px;
    height: 44px;
    padding: 4px 12px;
    border: 4px solid #5C63E6;
    transition: left .5s, top .3s;
  }

  &:hover {
    &::before{
      transition: left .3s, top .3s;
      left: 0;
      top: 0;
    }
  }
`
