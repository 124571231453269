import React from "react"
import styled from "styled-components"
import service from "../images/service.png"
import { Buttons } from "./_buttons"

export class Service extends React.Component {
  render() {
    return(
      <Main>
        <h2 className="title">アフィリエイター向けサービス</h2>
        <Contents className="contents">
          <img src={service} alt="Performance First"/>
          <div className="text">
            <h3>集客支援サービス</h3>
            <p>他社ASP社と異なり、弊社独自ノウハウを用いた集客支援サービスを無償（※）で実施しております。</p>
            <p>1,000社以上の実施した施策や蓄積されたデータを基に独自指標を構築し、500サイト以上のアフィリエイトサイトを分析・改善支援を行ってます。</p>
            <p>
              ※弊社が定める一定条件を満たしている場合。<br />
              ※サービス内容よってオプションのメニューもございます。
            </p>
          </div>
        </Contents>
        <Buttons />
      </Main>
    )
  }
}

const Main = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  > .title {
    text-align: center;
  }

`

const Contents = styled.div`

  @media screen and (min-width: 900px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .text {
    margin: 32px;
    @media screen and (min-width: 900px) {
      max-width: 520px;
      margin: 0 64px 0 0 ;
    }
  }

  > img {
    width: 90%;
    max-width: 240px;
    margin: 32px auto auto;
    display: block;
  }

`
