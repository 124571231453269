import React from "react"
import styled from "styled-components"
import question from "../images/question.svg"
import answer from "../images/answer.svg"

const AccordionItem = ({ items }) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Card open={expanded} onClick={() => setExpanded(!expanded)}>
      <span className="question">
        <img src={question} alt="Q" className="question-image"/>
        {items.question}
      </span>
      <div className="answer" open={expanded}>
        <img src={answer} alt="A" className="answer-image"/>
        {items.answer}
      </div>
    </Card>
  )
}

const AccordionItems = () => {
  const list = [
    {
      question: "会員登録は費用がかかりますか？",
      answer: "PERFORMANCE FIRSTへの会員登録は無料です。登録した後に、自身のご興味のある広告をご紹介致します。",
    },
    {
      question: "登録はどのようにするのですか？",
      answer: 'メディアオーナー登録のフォームから登録申請をお願いしております。 \n ※広告掲載する前に、広告主の審査が入ります。',
    },
    {
      question: "報酬はどのように発生しますか？",
      answer: "広告を経由して商品が購入されたとき、お礼として報酬を獲得できます。成果に応じた報酬形態となっております。",
    },
    {
      question: "報酬が振り込まれるのはいつですか？",
      answer: "末日までの成果承認された報酬額は、翌々月15日までに銀行口座に振り込まれます。 ※金融機関が休日にあたる場合は、翌営業日となります。",
    },
    {
      question: "振込手数料はかかりますか？",
      answer: "振込手数料は当社が負担致します。 ※ご登録の口座情報に誤りなどがあった場合、手数料等はご負担いただく可能性がございます。",
    },
    {
      question: "最低支払報酬はいくらですか？",
      answer: "報酬金額が3,000円（税別）を超えると登録口座に振込みます。3,000円未満の場合は、翌月繰り越しになります。",
    },
  ]

  return list.map((list, i) => (
    <AccordionItem items={list} key={`${list.question.toLowerCase()}_${i}`} />
  ))
}


export class Faq extends React.Component {
  render() {
    return(
      <Main>
        <h2 className="title">よくある質問</h2>
        <div className="desc">Performance Firstでアフィリエイト広告を開始するにあたって、アフィリエイトオーナー様からよくあるご質問をご紹介致します。</div>
        <Accordions>
          <AccordionItems />
        </Accordions>
      </Main>
    )
  }
}

const Main = styled.div`

  > .title {
    text-align: center;
  }

  > .desc {
    width: 90%;
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
  }
`

const Accordions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1200px;
  margin: 48px auto 100px;
`

const Card = styled.div`
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  padding: 16px 32px;
  margin: 16px auto;
  cursor: pointer;
  max-height: 56px;
  transition: max-height .3s;

  &[open] {
    max-height: 300px;
    transition: max-height .3s;
  }

  > .question {
    font-weight: bold;
    line-height: 24px;
    position: relative;
  }

  & .question-image {
    position: absolute;
    width: 36px;
    left: -32px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  > .answer {
    display: none;
    position: relative;
  }

  > .answer[open] {
    display: block;
    margin-top: 16px;
  }


  & .answer-image {
    position: absolute;
    width: 36px;
    left: -32px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

`
