import React from "react"
import styled from "styled-components"
import num1 from "../images/num1.svg"
import num2 from "../images/num2.svg"
import num3 from "../images/num3.svg"

export class Feature extends React.Component {
  render() {
    return(
      <Main>
        <h2 className="title">Performance Firstを活用したアフィリエイト運用の特徴</h2>
        <div className="desc">デジタル広告業界を牽引する株式会社オプトならではのアフィリエイト運用の特徴をご紹介致します。</div>

        <Contents>
          <div className="cards">
            <h3>広告主様へ</h3>
            <Card className="contents">
              <img src={num1} alt="1" className="num"/>
              <div className="cards-title">直運用による圧倒的な実績とノウハウ</div>
              <div className="cards-text">通常のアフィリエイト商流とは異なり、自社で直接媒体管理を行いASP社を利用しないため、コストカットが実現可能。そのため、獲得件数の最大化とコスト最適化が行いやすいです。</div>
            </Card>
            <Card className="contents">
              <img src={num2} alt="2" className="num"/>
              <div className="cards-title">安定した運用スキルのご提供</div>
              <div className="cards-text">大手金融案件の運用で培ったアフィリエイトオーナー様毎の細かなプランニング・施策実行と数値管理を徹底しています。</div>
            </Card>
            <Card className="contents">
              <img src={num3} alt="3" className="num"/>
              <div className="cards-title">専門性の高いサイトパトロール管理体制を構築</div>
              <div className="cards-text">システムによる監視だけでなく、サイト単位で広告主様の広告ガイドラインに沿った表記であるかなど、専門チームが目視で確認し、必要に応じて直接表記内容の修正を行っています。</div>
            </Card>
            <div className="notes">※対応内容によっては別途オプションサービスとなります。</div>
          </div>
          <div className="cards">
            <h3>アフィリエイトオーナー様へ</h3>
            <Card className="contents">
              <img src={num1} alt="1" className="num"/>
              <div className="cards-title">大手金融案件を多数取引</div>
              <div className="cards-text">メガバンクやネットバンクなどのカードローン中心に大手ネット証券や話題の自動資産運用案件など幅広いジャンルの広告主様がご利用頂いております。</div>
            </Card>
            <Card className="contents">
              <img src={num2} alt="2" className="num"/>
              <div className="cards-title">直案件が中心</div>
              <div className="cards-text">直接広告主様とお取引しているため、報酬単価を最大限還元できる環境です。また直案件の場合、間にASP社を挟まないため、広告主様へアフィリエイトオーナー様のご要望等をタイムリーに伝達しやすくなります。</div>
            </Card>
            <Card className="contents">
              <img src={num3} alt="3" className="num"/>
              <div className="cards-title">独自サービスのご提供</div>
              <div className="cards-text">弊社独自ノウハウを用いた集客支援サービスを無償で実施しております。1,000社以上の実施した施策や蓄積されたデータを基に独自指標を構築し、500サイト以上のアフィリエイトサイトを分析・改善支援を行ってます。</div>
            </Card>
            <div className="notes">※弊社が定める一定条件を満たしている場合。<br />※サービス内容よってオプションのメニューもございます。</div>
          </div>
        </Contents>
      </Main>
    )
  }
}

const Main = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  > .title {
    text-align: center;
  }

  > .desc {
    width: 90%;
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
  }
`

const Card = styled.div`
  width: 90%;
  text-align: left;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  padding: 32px 64px;
  margin: 0 auto 24px;


  > .num {
    width: 48px;
    position: absolute;
    left: -32px;
    top: 0;
  }

  > .cards-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 8px;

  }

  @media screen and (min-width: 600px) {
    border-radius: 120px;

    > .num {
      width: 64px;
    }
  }

  @media screen and (min-width: 900px) {
    > .num {
      width: 80px;
    }
  }

`

const Contents = styled.div`
  margin: 48px auto;


  @media screen and (min-width: 800px) {
    display: flex;

    > .cards {
      width: 48%;
    }
  }

  > .cards {
    width: 90%;
    margin: 0 auto auto;
    text-align: center;
  }

  & .notes {
    font-size: .8rem;
    width: 80%;
    text-align: left;
    margin: 16px auto;
  }
`
