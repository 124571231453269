import React from "react"
import { Helmet } from "react-helmet"
// import { Link } from "gatsby"
// import Layout from "../_components/layout"
// import Image from "../_components/image"
// import SEO from "../_components/seo"
import "../components/reboot.css"
import "../components/common.css"
import { Top } from "../components/top"
import { Feature } from "../components/feature"
import { Affiliate } from "../components/affiliate"
import { Faq } from "../components/faq"
import { Service } from "../components/service"
import { Footer } from "../components/footer"
// import { Clients } from "../components/clients"

class Scripts extends React.Component {
  render() {
    return(
      <Helmet>
        <script>
          {`
          (function(d) {
            let config = {
              kitId: 'vpc3qnm',
              scriptTimeout: 3000,
              async: true
            },
            h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
          })(document);
          `}
        </script>
        <script defer>
          {`
            var timeStamp = (new Date()).getTime();
            var btnTargets = document.getElementsByClassName('btn_form_link');
            for (let i = 0; i < btnTargets.length; i++) {
              btnTargets[i].href = 'https://console.performancefirst.jp/report/index.php?a=login/seller/SellerRegist&nocache=' + timeStamp;
            }
          `}
        </script>
      </Helmet>
    )
  }
}

const IndexPage = () => (
  <>
    <Scripts />
    <Top />
    <Feature />
    <Affiliate />
    {/* <Clients /> */}
    <Faq />
    <Service />
    <Footer />
  </>
  // <Layout>
  //   <SEO title="Home" />
  //   <h1>Hi people</h1>
  //   <p>Welcome to your new Gatsby site.</p>
  //   <p>Now go build something great.</p>
  //   <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
  //     <Image />
  //   </div>
  //   <Link to="/page-2/">Go to page 2</Link> <br />
  //   <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
  // </Layout>
)

export default IndexPage
