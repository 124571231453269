import React from "react"
import styled from "styled-components"
import logo_horizontal_white from "../images/logo_horizontal_white.png"
import display from "../images/display.png"


export class Top extends React.Component {
  render() {
    let tempLink = "https://console.performancefirst.jp/report/index.php?a=login/seller/SellerRegist&nocache=";
    return(
      <Main>
        <header className="header">
          <a href="https://www.opt.ne.jp/contact_marketing/" target="_blank" rel="noopener noreferrer">広告出稿希望の企業ご担当者様はこちら</a>
          <a href={tempLink} target="_blank" rel="noopener noreferrer" className="btn_form_link">アフィリエイトオーナー様はこちら</a>
        </header>
        <div className="main">
          <img className="logo" src={logo_horizontal_white} alt="Performance First"/>
          <h1>成果を追求する人のための<DesktopBr />アフィリエイトプラットフォーム</h1>
          <div className="desc">PERFORMANCE FIRSTとは、株式会社オプトが運営する金融ジャンル特化型のアフィリエイト計測ツールです。</div>
          <div className="links">
            <div>
              <a href="https://www.opt.ne.jp/contact_marketing/" target="_blank" rel="noopener noreferrer">広告出稿希望の企業ご担当者様はこちら</a>
            </div>
            <div>
              <a href={tempLink} target="_blank" rel="noopener noreferrer" className="btn_form_link" >アフィリエイトオーナー様はこちら</a>
            </div>
          </div>
        </div>
        <img className="display" src={display} alt="Performance First"/>
      </Main>
    )
  }
}

const DesktopBr = styled.br`
  display: none;
  @media screen and (min-width: 900px) {
    display: block;
  }
`

const Main = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: transparent linear-gradient(58deg, #1f2599 0%, #5C63E6 100%) 0% 0% no-repeat padding-box;
  margin-bottom: 128px;
  color: #fff;

  > .header {
    display: none;
  }

  > .main {
    padding-top: 8vh;
    margin-left: 0;
  }

  ::after {
    content: "";
    display: block;
    width: 100%;
    height: 128px;
    position: absolute;
    bottom: -64px;
    background: #fff;
    transform: skewY(-5deg);
  }

  > .display {
    display: none;
  }

  & .logo {
    width: 80%;
    margin: auto;
    display: block;
  }

  & h1 {
    max-width: 600px;
    width: 80%;
    margin: 4vh auto 4vh;
    font-size: 1.8rem;
  }

  & .desc {
    max-width: 600px;
    width: 80%;
    margin-top: 5vh;
    margin: auto;
    line-height: 2;
    font-size: 1.2rem
  }

  & .links {
    max-width: 600px;
    width: 80%;
    margin: 0 auto;
    padding: 16px;
    text-align: center;
    font-size: .9rem;

    & div {
      margin: 24px 0;
    }

    & a {
      padding: 8px;
      border-bottom: 1px solid #fff;
      color: #fff;
      text-decoration: none;
    }
  }


  @media screen and (min-width: 900px) {

    & .logo {
      width: auto;
      display: inline;
    }

    & .links {
      display: none;
    }

    > .header {
      width: 100%;
      display: block;
      max-width: 1280px;
      margin: 0 auto;
      padding: 16px;
      text-align: right;

      & a {
        padding: 8px;
        border-bottom: 1px solid #fff;
        color: #fff;
        margin: 16px 8px;
        text-decoration: none;
      }
    }

    > .main {
      margin-top: 8vh;
      margin-left: 10vw;
    }

    & h1 {
      max-width: 520px;
      width: 52%;
      font-size: 1.9rem;
      margin: 8vh auto auto 0;
    }


    & .desc {
      max-width: 540px;
      width: 50%;
      margin-top: 5vh;
      line-height: 2;
      font-size: 1.3rem;
      margin: 8vh auto auto 0;
    }

    > .display {
      display: block;
      position: absolute;
      z-index: 1;
      bottom: 32px;
      right: 8%;
      width: 36vw;
    }
  }

  @media screen and (min-width: 1080px) {
    & h1 {
      font-size: 2.4rem;
    }

    & .desc {
      font-size: 1.4rem;
    }
  }
`
