import React from "react"
import styled from "styled-components"
import affiliate from "../images/affiliate.png"

export class Affiliate extends React.Component {
  render() {
    return(
      <Main>
        <h2 className="title">アフィリエイトとは</h2>
        <div className="desc">アフィリエイトとは、インターネットを利用した広告宣伝のひとつで成果報酬型の広告のことです。</div>
        <Contents className="contents">
          <img src={affiliate} alt="アフィリエイトとは"/>
          <div className="text">
            <p>WEBサイトの閲覧者がWEBサイトに掲載している広告主の商品あるいはサービスなどを購入し、生じた利益に応じて広告を掲載したWEBサイトに成功報酬をお支払する一連のインターネット広告手法です。</p>
            <p>弊社アフィリエイトには、クレジットカードやカードローンを始めとする金融商材や化粧品や健康食品など様々な商材の広告主様とお取引しております。</p>
          </div>
        </Contents>
      </Main>
    )
  }
}

const Main = styled.div`
  width: 90%;
  max-width: 960px;
  margin : 32px auto;


  > .title {
    text-align: center;
  }

  > .desc {
    width: 90%;
    max-width: 440px;
    margin: 0 auto;
    text-align: center;
  }

`

const Contents = styled.div`

  margin: 32px auto 128px;

  @media screen and (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }

  > .text {
    margin: 32px;
    @media screen and (min-width: 900px) {
      max-width: 520px;
      margin: 0 64px 0 0 ;
    }
  }

  > img {
    width: 90%;
    max-width: 480px;
    margin: auto;
    display: block;
  }
`
